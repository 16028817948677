<template>
  <div
    class="konfetti-hero md:flex items-center my-8"
    :class="[{ 'flex-row-reverse': dir === 'right' }, `hero-${dir}`]"
  >
    <div
      v-if="isDesktopOrTablet"
      class="konfetti-hero__image-container md:flex-1"
    >
      <NuxtImg
        :src="isDesktopOrTablet ? image : mobileImage"
        alt=""
        :height="isDesktopOrTablet ? 356 : 271"
        class="d-block mx-lg-auto img-fluid rounded-lg h-[400px] overflow-hidden w-full object-cover"
        :fit="dir === 'right' ? 'cover' : undefined"
        :class="imgClasses"
      />
    </div>
    <div :class="{ 'parent-overlay-active': overlay }" class="md:flex-1">
      <div
        :class="{ 'overlay-active': overlay }"
        class="konfetti-hero__text-container rounded-lg"
      >
        <div class="lc-block mb-3 pt-1">
          <Heading
            :level="3"
            :title="title"
            class="kft-heading--left"
            :class="{ ' mt-2': isDesktopOrTablet }"
            h-class="mb-2"
          />
        </div>

        <div class="lc-block mb-8">
          <p class="lead">{{ subtitle }}</p>
        </div>

        <div class="lc-block d-grid gap-2 d-md-flex justify-content-md-start">
          <component
            :is="link !== '#' ? NuxtLink : 'div'"
            :to="link"
            v-bind="$attrs"
          >
            <Button
              class="w-full py-3"
              :data-bs-target="dataBs ? bsTarget : null"
              :data-bs-toggle="dataBs ? 'modal' : null"
              @click="$emit('click:button')"
            >
              {{ buttonText }}
            </Button>
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'

const { isMobile, isDesktopOrTablet } = useDevice()
defineEmits('click:button')
defineProps({
  dir: {
    type: String,
    default: 'left',
  },
  link: {
    type: String,
    default: '#',
  },
  image: {
    type: String,
    default:
      'https://images.unsplash.com/photo-1530435460869-d13625c69bbf?crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8MTB8fHdlYnNpdGV8ZW58MHwwfHx8MTYyMTQ0NjkyNg&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1080&amp;h=768',
  },
  mobileImage: {
    type: String,
    default:
      'https://images.unsplash.com/photo-1530435460869-d13625c69bbf?crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8MTB8fHdlYnNpdGV8ZW58MHwwfHx8MTYyMTQ0NjkyNg&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1080&amp;h=768',
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  overlay: {
    type: Boolean,
    default: false,
  },
  buttonText: {
    type: String,
    default: '',
  },
  lazyHydrate: {
    type: Boolean,
    default: true,
  },
  imgClasses: {
    type: String,
    default: '',
  },
  dataBs: {
    type: Boolean,
    default: false,
  },
  bsTarget: {
    type: String,
    default: '',
  },
})
</script>

<style lang="postcss" scoped>
.konfetti-hero__image {
  max-height: var(--image-height);
  width: var(--image-width);
}

.konfetti-hero .parent-overlay-active {
  position: relative;
}

.konfetti-hero__text-container {
  padding: 0;
  background-color: transparent;
}
@screen md {
  .konfetti-hero__text-container {
    background-color: #fbfbfb;
    padding: 0.5rem 1.5rem 1.5rem;
  }
  .overlay-active {
    width: calc(100% + 3rem);
    margin-left: -3rem;
  }
  .konfetti-hero.hero-right .overlay-active {
    margin-right: -3rem;
    margin-left: unset;
  }
}

.konfetti-hero.hero-right .parent-overlay-active {
  position: relative;
}
</style>
