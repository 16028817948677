<template>
  <div id="home">
    <BannerGallery />
    <div class="relative sm:hidden">
      <TeamEventRequestButton />
    </div>
    <div v-if="lastViewedEvents?.length > 0">
      <ProductShowcase
        data-test-id="last-viewed-events-section"
        :loading="loading"
        :products="lastViewedEvents"
        image-loading="lazy"
        :gtm-item-list-id="GtmItemListId.GeneralPageLastViewedEvents"
        :tags="[]"
        :title="$t('home.lastViewedEvents')"
        algolia-insights-label="Last Viewed Events - Homepage"
        class="mt-16"
      />
    </div>

    <ProductShowcase
      data-test-id="top-events-section"
      :loading="loading"
      :products="topEvents"
      image-loading="lazy"
      :gtm-item-list-id="GtmItemListId.GeneralPageTopEvents"
      :query-id="topEventsQueryID"
      :algolia-insights-client="insightsClient"
      :tags="[]"
      :title="$t('home.browseTopEvents')"
      algolia-insights-label="Top Events - Homepage"
      class="mt-16"
    />
    <ProductGiftcardEventsShowcase :title="$t('home.inspirationForYou')" />
    <ProductShowcase
      data-test-id="recommended-events-section"
      :loading="loading"
      :products="filteredGiftIdeas"
      :query-id="topGiftIdeasEventsQueryID"
      :algolia-insights-client="insightsClient"
      :gtm-item-list-id="GtmItemListId.GeneralPageTopGiftIdeas"
      :tags="[]"
      :title="$t('home.recommendedForYou')"
      algolia-insights-label="Gift Ideas For You - Homepage"
      class="mt-8 md:mt-16"
    />
    <HeroImage
      dir="right"
      :button-text="$t('home.heroes.second.buttonText')"
      :image="$t('home.heroes.second.image')"
      :link="localePath('/teamevent')"
      :mobile-image="$t('home.heroes.second.mobileImage')"
      :subtitle="$t('home.heroes.second.subtitle')"
      :title="$t('home.heroes.second.title')"
      class="kft-section-spacing-large my-14 md:my-24"
      lazy-hydrate
      overlay
    />
    <ProductShowcase
      data-test-id="new-events-section"
      :loading="loading"
      :products="newEvents"
      :gtm-item-list-id="GtmItemListId.GeneralPageNewEvents"
      :query-id="newEventsQueryID"
      :algolia-insights-client="insightsClient"
      :tags="[]"
      :title="$t('home.newEventsForYou')"
      algolia-insights-label="New Events - Homepage"
      class="mt-8 md:mt-16"
    />
  </div>
</template>
<script setup lang="ts">
import organizationSchema from '~/constants/organizationSchema'
import { useHomepageAlgolia } from '~/composables/useHomepageAlgolia'
import { eventSectionComposableFactory } from '~/composables/algolia/states'
import { useLastViewedEvents } from '~/composables/useLastViewedEvents'
import { GtmItemListId } from '~/composables/useKftGtm/types'
import { useAlgoliaSearchInsights } from '~/composables/algolia/useAlgoliaSearchInsights'

const { locale } = useI18n()
const currentLocale: string = locale.value
const { client: insightsClient } = useAlgoliaSearchInsights()

const { algoliaResults, pending: loading } = await useHomepageAlgolia()
const useTopEvents = eventSectionComposableFactory(0)
const useNewEvents = eventSectionComposableFactory(1)
const useTopGiftIdeasEvents = eventSectionComposableFactory(2)

const { data: topEvents, queryId: topEventsQueryID } =
  useTopEvents(algoliaResults)
const { data: newEvents, queryId: newEventsQueryID } =
  useNewEvents(algoliaResults)
const { data: topGiftIdeasEvents, queryId: topGiftIdeasEventsQueryID } =
  useTopGiftIdeasEvents(algoliaResults)

const { lastViewedEvents } = useLastViewedEvents(true)

const filteredGiftIdeas = computed(() =>
  topGiftIdeasEvents.value
    .filter(
      (event) =>
        !topEvents.value
          .map((topEvent) => topEvent.objectID)
          .includes(event.objectID)
    )
    .slice(0, 10)
)

useHead({
  script: [
    {
      type: 'application/ld+json',
      innerHTML: organizationSchema[currentLocale],
    },
  ],
})
</script>
